
export default {


    //api_url_cloud: "https://cloud.technosoftcr.com/cloudwebapi/",//'https://cloud3.technosoftcr.com/test/',    http://cloud3.technosoftcr.com/Cloudwebapi/
    //api_url: 'https://localhost:7204/',
    //api_url_reportes: 'https://localhost:44374/',
    //api_url_reportes_api: 'api/',
    //login_url: "index.html#/", // 'http://localhost:8080/',
    //api_url_signalr: "https://localhost:7204/myhub",

    api_url_cloud: 'https://cloud.technosoftcr.com/cloudwebapi/',    // https://cloud.technosoftcr.com/cloudwebapi/        https://cloud3.technosoftcr.com/Cloudwebapi/    https://cloud3.technosoftcr.com/test/
    api_url: 'https://api.technosoftcr.com/inventariosv2/',    //          https://apixpress.technosoft.co.cr/invetestapi/        https://api.technosoftcr.com/inventariosv2/
    api_url_reportes: 'https://api.technosoftcr.com/inventariosv2_reportes/',    //         https://apixpress.technosoft.co.cr/invetestapi/        https://api.technosoftcr.com/inventariosv2_reportes/
    api_url_reportes_api: 'api/',
    login_url: "index.html#/", // 'https://inventarios2.technosoftcr.com/', //        https://inventariostest2.technosoft.co.cr/          https://inventarios2.technosoftcr.com/
    api_url_signalr: 'https://api.technosoftcr.com/inventariosv2/myhub', //        https://apixpress.technosoft.co.cr/invetestapi/myhub          https://api.technosoftcr.com/inventariosv2/myhub

    app_cod: '010',
    httpTimeOut: 500000,
    urlDescargas: "assets/descargas/",
    app_cod_contador_xpress: "012",
    app_cod_facel: "013",

    formatoFecha: "DD/MM/yyyy",
    formatoFechaHora: "DD/MM/yyyy hh:mma",
    formatoFechaInput: "dd/MM/yyyy",

    blocked_msg_free_plan1: "Para tener acceso a esta sección/acción debe actualizar su plan gratuito",
    blocked_msg_role1: "El rol del usuario actual no tiene permitido el acceso a esta sección/acción",

    statusCodeExpiredToken: 401, // 401 por el momento, la idea es en algún momento configurar otro que pueda detectarse correctamente

    vencimiento_plan_dia_mostrar_alerta_inicial: 7,
    vencimiento_plan_dia_gracia: -7,
    cobros_info_telefono: "2105-8095",
    cobros_info_email: "cobros@facelcr.com"


}