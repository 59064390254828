import moment from 'moment';
import numeral from 'numeral';
import config from './../config';

var defaultLengthDB = 17; // 18 es la base de datos, pero voy a ponerlo 17 para que acepte solo 12 enteros y 5 decimales, para evitar una caída por overflow
var defaultDecimal = 5;
var defaultDecimalFormat = 2;

const Filters = (app) => {

    app.config.globalProperties.$filters = {

        // {{ $filters.dateFormat(variableFecha) }} - Este solo es fecha
        dateFormat(value) {

            return moment(value).format(config.formatoFecha);

        },

        // {{ $filters.dateFormat(variableFecha) }} - Este tiene fecha y hora formateada
        dateTimeFormat(value) {

            return moment(value).format(config.formatoFechaHora);

        },

        // {{ $filters.numberFormat(variableMonto) }}
        numberFormat(value, decimal) {

            if (decimal == null)
                decimal = defaultDecimalFormat;

            var num = 0;
            var varPadeada = num.toString().padStart(decimal, '0');


            return numeral(value).format(`0,0.${varPadeada}`)
            // displaying other groupings/separators is possible, look at the docs

        },

        // Esto sirve cuando se hacen cálculos y tiene demasiados decimales, entonces se asigna la cantidad de decimales estimado y se devuelve como decimal no como string
        // this.$filters.numberFormatDecimalInput(variableDecimal)
        numberFormatDecimalInput(value, decimal) {

            if (value.toString().includes(",") || value.toString().includes(".")) {

                if (decimal == null)
                    decimal = defaultDecimal;

                let num = 0;
                let varPadeada = num.toString().padStart(decimal, '0');

                return parseFloat(numeral(value).format(`0.${varPadeada}`))

            } else {

                return parseFloat(numeral(value).format(`0`))

            }

        },




        // Acepta solo decimales (decimales y cantidadTotalDecimalEnBase son opcionales, en caso de no querer indicarlos, revisar los "por defecto" en el método de abajito jeje)
        // <input type="text" @keypress="$filters.OnlyDecimals($event, 2);" @paste="$filters.OnlyDecimalsPaste($event, 2);" />
        OnlyDecimals(evt, decimales, cantidadTotalDecimalEnBase, maxLength, noNegativo) {

            let key = evt.which || evt.keyCode;
            let chark = String.fromCharCode(key);

            return this.OnlyDecimalsValidRegex(evt, decimales, cantidadTotalDecimalEnBase, chark, maxLength, noNegativo);

        },
        OnlyDecimalsPaste(evt, decimales, cantidadTotalDecimalEnBase, maxLength, noNegativo) {

            let textoIngresado = evt.clipboardData.getData('text');

            return this.OnlyDecimalsValidRegex(evt, decimales, cantidadTotalDecimalEnBase, textoIngresado, maxLength, noNegativo);

        },
        OnlyDecimalsValidRegex(evt, decimales, cantidadTotalDecimalEnBase, textoIngresado, maxLength, noNegativo) {

            const input = evt.target;

            var textoActual = "";

            if (input.value != null && input.value.length > 0) {

                if (input.selectionStart == input.value.length) { // Si se ingresó algo al puro final del input, nada mas lo agrega al final y listo

                    textoActual = input.value + textoIngresado;

                } else { // Si ingresó un texto en otra parte que no es el final, tiene que recorrer para ingresarlo donde realmente se digitó

                    for (var i = 0; i < input.value.length; i++) {

                        if (i == input.selectionStart) // Cuando ya va por la posición donde estaba el Focus, lo ingresa
                            textoActual += textoIngresado;

                        if (input.selectionStart != input.selectionEnd) {

                            if (i >= input.selectionStart && i < input.selectionEnd) {

                                // En teoría no ingresa nada, ya que se sustituye

                            } else {

                                textoActual += input.value.charAt(i);

                            }

                        } else {

                            textoActual += input.value.charAt(i);

                        }


                    }

                }

            } else {

                textoActual += textoIngresado;

            }




            if (noNegativo == null)
                noNegativo = false;

            if (decimales == null)
                decimales = defaultDecimal;

            if (cantidadTotalDecimalEnBase == null)
                cantidadTotalDecimalEnBase = defaultLengthDB;

            var cantidadNumeroRegex = cantidadTotalDecimalEnBase - decimales;

            let regex;

            if (decimales > 0 && textoActual.includes(".")) {

                if (noNegativo) {

                    regex = new RegExp(`^[0-9]{0,${cantidadNumeroRegex}}[.]?[0-9]{0,${decimales}}$`);

                } else {

                    regex = new RegExp(`^-?[0-9]{0,${cantidadNumeroRegex}}[.]?[0-9]{0,${decimales}}$`);

                }

            } else {

                if (noNegativo) {

                    regex = new RegExp(`^[0-9]{0,${cantidadNumeroRegex}}$`);

                } else {

                    regex = new RegExp(`^-?[0-9]{0,${cantidadNumeroRegex}}$`);

                }

            }

            if (regex.test(textoActual) === true) {
                //if (regex.test(textoActual)) {
                //return true;

                if (maxLength != null) {

                    if (textoActual > maxLength) {

                        evt.preventDefault();

                    } else {

                        return true;

                    }

                } else {

                    return true;

                }


            } else {
                //return false;
                evt.preventDefault();
            }

        },


        OnlyNumbers(evt, maxLength) {

            let key = evt.which || evt.keyCode;
            let chark = String.fromCharCode(key);

            return this.OnlyNumbersValidRegex(evt, chark, maxLength);

        },
        OnlyNumbersPaste(evt, maxLength) {

            let textoIngresado = evt.clipboardData.getData('text');

            return this.OnlyNumbersValidRegex(evt, textoIngresado, maxLength);

        },
        OnlyNumbersValidRegex(evt, textoIngresado, maxLength) {

            const input = evt.target;

            var textoActual = "";

            if (input.value != null && input.value.length > 0) {

                if (input.selectionStart == input.value.length) { // Si se ingresó algo al puro final del input, nada mas lo agrega al final y listo

                    textoActual = input.value + textoIngresado;

                } else { // Si ingresó un texto en otra parte que no es el final, tiene que recorrer para ingresarlo donde realmente se digitó

                    for (var i = 0; i < input.value.length; i++) {

                        if (i == input.selectionStart) // Cuando ya va por la posición donde estaba el Focus, lo ingresa
                            textoActual += textoIngresado;

                        if (input.selectionStart != input.selectionEnd) {

                            if (i >= input.selectionStart && i < input.selectionEnd) {

                                // En teoría no ingresa nada, ya que se sustituye

                            } else {

                                textoActual += input.value.charAt(i);

                            }

                        } else {

                            textoActual += input.value.charAt(i);

                        }


                    }

                }

            } else {

                textoActual += textoIngresado;

            }




            let regex;

            regex = new RegExp(`^[0-9]*$`);

            //regex = new RegExp(`^-?[0-9]{0,${cantidadNumeroRegex}}$`);

            if (regex.test(textoActual) === true) {
                //if (regex.test(textoActual)) {
                //return true;

                if (maxLength != null) {

                    if (textoActual > maxLength) {

                        evt.preventDefault();

                    } else {

                        return true;

                    }

                } else {

                    return true;

                }


            } else {
                //return false;
                evt.preventDefault();
            }

        },


        OnlyNumbersAndLetters(evt) {

            let key = evt.which || evt.keyCode;
            let chark = String.fromCharCode(key);

            return this.OnlyNumbersAndLettersValidRegex(evt, chark);

        },
        OnlyNumbersAndLettersPaste(evt) {

            let textoIngresado = evt.clipboardData.getData('text');

            return this.OnlyNumbersAndLettersValidRegex(evt, textoIngresado);

        },
        OnlyNumbersAndLettersValidRegex(evt, textoIngresado) {

            const input = evt.target;

            var textoActual = "";

            if (input.value != null && input.value.length > 0) {

                if (input.selectionStart == input.value.length) { // Si se ingresó algo al puro final del input, nada mas lo agrega al final y listo

                    textoActual = input.value + textoIngresado;

                } else { // Si ingresó un texto en otra parte que no es el final, tiene que recorrer para ingresarlo donde realmente se digitó

                    for (var i = 0; i < input.value.length; i++) {

                        if (i == input.selectionStart) // Cuando ya va por la posición donde estaba el Focus, lo ingresa
                            textoActual += textoIngresado;

                        if (input.selectionStart != input.selectionEnd) {

                            if (i >= input.selectionStart && i < input.selectionEnd) {

                                // En teoría no ingresa nada, ya que se sustituye

                            } else {

                                textoActual += input.value.charAt(i);

                            }

                        } else {

                            textoActual += input.value.charAt(i);

                        }


                    }

                }

            } else {

                textoActual += textoIngresado;

            }

            let regex;

            regex = new RegExp(`^[A-Za-z0-9]*$`);


            if (regex.test(textoActual) === true) {
                //if (regex.test(textoActual)) {
                //return true;

                return true;


            } else {
                //return false;
                evt.preventDefault();
            }

        },




        // <textarea class="form-control ttextArea" name="verifyHeightTextArea" onkeyup="$(this).height(1); $(this).height($(this).prop('scrollHeight'));"></textarea>
        VerifyHeightTextArea() {

            setTimeout(() => {

                var textAreas = document.querySelectorAll('[name="verifyHeightTextArea"]');

                if (textAreas.length > 0) {

                    textAreas.forEach(function (value) {

                        if (value.value != null && value.value != "") {

                            if (value.style.height == null || value.style.height == "")
                                value.style.height = "40px";

                            value.style.height = (value.scrollHeight + 5) + 'px';

                        } else {

                            value.style.height = "40px";

                        }

                    }, this);

                }

            }, 100);

        },


        // npm install b64-to-blob
        DownloadBase64File(base64, extension, nombre, mediaType) {

            let linkSource;
            let downloadLink;

            if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') > -1) {
                let fileName;
                linkSource = `data:application/${extension};base64,${base64}`;
                downloadLink = document.createElement("a");
                fileName = nombre;
                downloadLink.href = linkSource;
                downloadLink.download = fileName + "." + extension;
                downloadLink.click();
            } else {
                let b64toBlob = require('b64-to-blob');
                let blob = b64toBlob(base64, `data: ${mediaType}`);
                const blobUrl = URL.createObjectURL(blob);
                fetch(blobUrl).then(res => res.arrayBuffer()).then(res => {
                    const file = new Blob([res], { type: "application/octet-stream" }); // mediaType
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = nombre + "." + extension;
                    link.click();
                    link.remove();
                });
            }


        },

        DownloadBlobFile(blob, nombre, extension) {

            let mediaType = "application/octet-stream";

            switch (extension) {

                case "xls": case "xls2": case "xlsx":

                    mediaType = "application/vnd.ms-excel";
                    extension = "xls";

                    break;
                case "pdf":

                    mediaType = "application/pdf";
                    extension = "pdf";

                    break;
                case "doc": case "docx":

                    mediaType = "application/msword";
                    extension = "doc";

                    break;
                case "txt":

                    mediaType = "text/plain";
                    extension = "txt";

                    break;
            }

            if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
                let file = new Blob([blob], { type: `data: ${mediaType}` }) // text/csv;charset=utf-8   attachment/pdf
                let fileURL = URL.createObjectURL(file);
                let a = window.document.createElement("a");
                a.target = '_blank';
                a.href = fileURL;

                let e = window.document.createEvent("MouseEvents");
                e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);

            } else {

                let fileName = `${nombre}.${extension}`;
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                let myBlob = new Blob([blob], { type: `data: ${mediaType}` });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(myBlob, fileName);
                } else {
                    let blobURL = window.URL.createObjectURL(myBlob);

                    a.href = blobURL;
                    a.download = fileName;
                    a.click();
                }
            }

        },



        ValidateValidEmail(correo) {

            let regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
            //let regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,}$/; 
            //let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            //let regex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;
            //let regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
            //let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            //let regex = /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[^\s"\\]|\\[\s\S])*")|[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            //const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            //const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            
            

            if (regex.test(correo)) {
                return true;
            } else {
                return false;
            }
        },


        // La idea es revisar cualquier tipo de "validación" que impida poder ver alguna acción (sea por rol, plan gratuito o por permiso)
        ValidateActionAccess(allowedRoles, blockFreePlan, permission, returnBool) {

            let allowed = true;
            let msg = "";
            let blockedByRole = false;
            let blockedByFreePlan = false;
            let blockedByPermission = false;
            let roles = [];
            let role = localStorage.getItem("tech_uTipo");

            if (returnBool == null)
                returnBool = false;

            if (allowedRoles != null) {

                allowedRoles = allowedRoles.split(",");

                allowedRoles.forEach((value) => {
                    roles.push(value.replace(" ", ""));
                });

                allowed = roles.some(allowedRole => allowedRole == role);

                if (!allowed) {
                    msg = config.blocked_msg_role1;
                    blockedByRole = true;
                }
                

            }

            if (blockFreePlan) {

                let planId = localStorage.getItem("tech_planId");

                if (planId == 55) {

                    if (allowed) {

                        allowed = false;
                        msg = config.blocked_msg_free_plan1;

                    }

                    blockedByFreePlan = true;

                }

            }
            // No tiene else, si ya viene FALSE es porque está bloqueado, no se necesita volver a validar otra vez

            if (returnBool) {

                return allowed;

            } else {

                return {
                    allowed: allowed,
                    msg: msg,
                    blockedByRole: blockedByRole,
                    blockedByFreePlan: blockedByFreePlan,
                    blockedByPermission: blockedByPermission,
                    blockedByFreePlanOnly: (!blockedByRole && !blockedByPermission && blockedByFreePlan)
                };

            }

        },


        // $filters.ObjectConvertDatesToISOString({}) // si no viene devuelveObjeto se asigna FALSE, por ende, devuevle el JSON
        ObjectConvertDatesToISOString(objeto, devuelveObjeto) {

            if (devuelveObjeto == null) {

                devuelveObjeto = false;

            }

            objeto = { ...objeto };

            var valor = "";

            for (var p in objeto) {

                if (Object.prototype.hasOwnProperty.call(objeto, p)) {

                    valor = "";

                    if (typeof objeto[p] === 'object') {

                        valor = objeto[p];

                        if (valor instanceof Date) {

                            let year = valor.getFullYear();
                            let month = (valor.getMonth() + 1).toString().padStart(2, '0');
                            let day = valor.getDate().toString().padStart(2, '0');
                            let hours = valor.getHours().toString().padStart(2, '0');
                            let minutes = valor.getMinutes().toString().padStart(2, '0');
                            let seconds = valor.getSeconds().toString().padStart(2, '0');

                            objeto[p] = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


                            //console.log(objeto[p]);

                            //valor = valor.toISOString();
                            //objeto[p] = valor;


                            //console.log((encodeURIComponent(p) + "=" + encodeURIComponent(valor)));

                        } else {

                            this.ObjectConvertDatesToISOString(objeto[p]);

                        }



                    } else {

                        valor = objeto[p];

                        if (valor instanceof Date) {

                            let year = valor.getFullYear();
                            let month = (valor.getMonth() + 1).toString().padStart(2, '0');
                            let day = valor.getDate().toString().padStart(2, '0');
                            let hours = valor.getHours().toString().padStart(2, '0');
                            let minutes = valor.getMinutes().toString().padStart(2, '0');
                            let seconds = valor.getSeconds().toString().padStart(2, '0');

                            objeto[p] = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


                            //valor = valor.toISOString();
                            //objeto[p] = valor;

                            //console.log(objeto[p]);

                        }

                        //console.log((encodeURIComponent(p) + "=" + encodeURIComponent(valor)));

                    }

                }

            }

            if (devuelveObjeto) {

                return objeto;

            } else {

                return JSON.stringify(objeto);

            }            

        },


        FillWith0(cantidad, texto) {

            if (texto != null && texto != '') {
                if (!isNaN(texto) && texto.length < 8) {
                    texto = ("0000000000000000000" + texto).slice(-cantidad);
                }
            }

            return texto;
        }


    }


};

export { Filters };


