import axios from 'axios'
import config from '@/config.js'
import connectorCloud from '@/common/connector-cloud.js';
//import connector from '@/common/connector.js';


const apiClient = axios.create({
    baseURL: config.api_url_cloud

})

export default {

    Token(data) {
        return connectorCloud.Token(data);
    },


    CuentasUsuarioLogin(data) {
        return connectorCloud.PostCuentasUsuarioLogin(data);
    },





    ListadoNotificacionesActivas(parametros) { // app
        return connectorCloud.Get("Mensaje", "getMensajesActivos", parametros);
    },







    postTokenOLD(data) {
        return apiClient.post('/token', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
    },
    postUserAccountsOLD(data) {
        return apiClient.post('api/General/PostUserAccounts', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    },
    postAdd(data) {
        return apiClient.post('api/Cuenta/PostAdd', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    },
    postConfirm(data) {
        return apiClient.post('api/Cuenta/PostConfirm', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    },
    postRemember(data) {
        return apiClient.post('api/Usuario/postRemeber', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    },









    postSaveProfile(data) {
        return apiClient.post('api/Usuario/postSaveProfile', data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getNotifications(fechaI, fechaF) {
        return apiClient.get('api/Mensaje/getMensajes?fechaInicio=' + fechaI + '&fechaFin=' + fechaF + '&estado=1&app=' + config.app_cod, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getNotificationsActive() {
        return apiClient.get('api/Mensaje/getMensajesActivos?&app=' + config.app_cod, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },

    postAcceptInviteExistingUser(data) {
        return apiClient.post('api/General/PostAcceptInviteExistingUser', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    postAcceptInviteUser(data) {
        return apiClient.post('api/General/PostAcceptInviteUser', data, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getCompannias() {
        return apiClient.get('api/General/GetCompannias', {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getSetCiaFavorite(ciaId) {
        return apiClient.get('api/General/GetSetCiaFavorite?pCompannia=' + ciaId, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getCiaFavorite() {
        return apiClient.get('api/General/GetCiaFavorite', {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getCompanniasUnasigned() {
        return apiClient.get('api/General/GetUnasignedCompannias', {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getCompanniaAsignApp(cia_id) {
        return apiClient.get('api/General/GetCompanniaAsignApp?pNumero=' + cia_id, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    getCompanniaUnasignApp(cia_id) {
        return apiClient.get('api/General/GetCompanniaUnasignApp?pNumero=' + cia_id, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
    GetCompanniasApp(appCod) { // Obtiene las compa��as de otra aplicaci�n (Facel o Conta)
        return apiClient.get('api/General/GetCompanniasApp?app=' + appCod, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem("tech_token")
            }
        })
    },
}