<template>

    <div class="login-box card"  style="height: 100% !important">
        <div class="card-body">

            <div style="text-align: center;">

                <!-- width="600" height="373.5" -->
                <!--<iframe style="width: 100%; height: 500px;" title="Poblacion-Sesion1-0" src="https://app.powerbi.com/view?r=eyJrIjoiYmZmZDgzMmItYjMxMC00N2Q5LTllZWUtNzU0ZTA2Y2U5MWVmIiwidCI6IjVjMGU4ZTk3LTA3MmQtNDVjOC05OTM4LTZlY2Y5ZDc0ZWI4YyIsImMiOjR9" frameborder="0" allowFullScreen="true"></iframe>-->
                <img src="../../../assets/error-bg.jpg" style="width: 100%; max-width: 500px !important;" />

                <!--<br /><br />

                <i class="fas fa-frown" style="font-size: 150px;"></i>

                <br /><br />-->

                <div>
                    Hubo un problema
                    <br />
                    Intente nuevamente
                    <br />
                    <br />
                    <router-link to="/" class="btn btn-primary"> Volver al Inicio </router-link>

                </div>

            </div>

            <!--<div style="text-align: center;">
                <iframe title="ProyectoDirect" style="width: 100%;" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiMzE3MDBjNTktNzk2ZC00MzE2LThiZWQtNGJmNWQ1ZDg3ODQyIiwidCI6IjVjMGU4ZTk3LTA3MmQtNDVjOC05OTM4LTZlY2Y5ZDc0ZWI4YyIsImMiOjR9" frameborder="0" allowFullScreen="true"></iframe>
            </div>-->
        </div>
    </div>


</template>

<script>

    export default {

        mounted: function () {

            this.$loading.hide();

        }

    };
</script>

<style>



</style>
