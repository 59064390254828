
import axios from 'axios'
import config from '@/config.js';


var url = config.api_url_cloud;
var pathAPI = "api/";
var returnResult = null;
var url2 = null;
var parametrosUrl = null;
var token = null;
var withCredentials = true;

var apiClient = axios.create({
    baseURL: url
});


export default {

    async Token(Data) {


        // Realiza la conexión al API
        var result = await apiClient.post(url + "api/JWT/token", Data, {
            timeout: config.httpTimeOut,
            withCredentials: false,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            //console.log(error);
            //window.location = "#/lostConnection";
            return error;

        });


        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.data != null) {

            returnResult = {
                ok: true,
                obj: result.data
            };

        } else {

            returnResult = {
                ok: false
            };


            if (result.response != null && result.response.data != null && result.response.data.error_description != null) {

                returnResult.msg = result.response.data.error_description;

            } else if (result?.response?.data?.message != null) {

                switch (result?.response?.data?.message) {

                    case "access_blocked", "invalid_grant": {

                        returnResult.msg = "Usuario o contraseña incorrectos";

                        break;
                    }
                    case "unconfirmed_user": {

                        returnResult.msg = "Usuario sin confirmación, por favor realizar la confirmación del mismo en el correo recibido luego del registro";

                        break;
                    }
                    case "inactive_user": {

                        returnResult.msg = "Usuario inactivo"; // inactivo de la compañía

                        break;
                    }
                    case "invalid_account": {

                        returnResult.msg = "Cuenta no existe, no esta relacionada o no esta asociada con la aplicación";

                        break;
                    }
                    case "inactive_account": {

                        returnResult.msg = "Cuenta no está activa";

                        break;
                    }
                    case "invalid_plan": {

                        returnResult.msg = "El plan se encuentra inactivo o no está asignado";

                        break;
                    }
                    case "unassigned_plan": {

                        returnResult.msg = "La compañía no tiene un plan relacionado";

                        break;
                    }
                    case "invalid_companny": {

                        returnResult.msg = "Usuario no tiene compañía asignada. Consulte con el administrador de la cuenta";

                        break;
                    }

                }

            } else {

                returnResult.msg = "No se ha podido iniciar sesión correctamente. Favor contacte a su proveedor";

            }


        }

        return returnResult;

    },
    async PostCuentasUsuarioLogin(Data) {

        url2 = pathAPI + "General" + "/" + "PostUserAccounts";


        // Realiza la conexión al API
        var result = await apiClient.post(url2, Data, {
            timeout: config.httpTimeOut,
            withCredentials: withCredentials,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            //window.location = "#/lostConnection";

            return error;

        });


        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.data != null) {

            if (result.data.length > 0) {

                returnResult = {
                    ok: true,
                    obj: result.data
                };

            } else {

                returnResult = {
                    ok: false,
                    msg: "El usuario ingresado no posee cuentas asignadas. Favor contacte a su proveedor"
                };

            }

        } else {

            returnResult = {
                ok: false
            };

            if (result.response != null && result.response.data != null) {

                if (result.response.data.message == "-1") {

                    returnResult.msg = "Usuario o Contraseña incorrectos. Favor verifique";

                } else {

                    returnResult.msg = (result.response.data.error_description != null ? result.response.data.error_description : "No se ha podido iniciar sesión correctamente. Favor contacte a su proveedor");

                }

            } else {

                returnResult.msg = "No se ha podido iniciar sesión correctamente. Favor contacte a su proveedor";

            }

        }

        return returnResult;

    },

    async Get(Controller, Metodo, Parametros, timeOut) {

        token = localStorage.getItem("tech_token");

        url2 = pathAPI + Controller + "/" + Metodo;


        if (timeOut == null)
            timeOut = config.httpTimeOut;


        // Verifica si viene un objeto de parámetros para hacer el agregado a la URL
        parametrosUrl = null;
        if (Parametros != null) {

            parametrosUrl = this.GenerarParametrosConObjeto(Parametros);

            if (parametrosUrl != null) {

                url2 += "?" + parametrosUrl;

            } else {

                return { ok: false, msg: "No se pudo generar la consulta correctamente" }

            }

        }


        // Realiza la conexión al API
        var result = await apiClient.get(url2, {
            timeout: timeOut,
            withCredentials: withCredentials,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            if (error.code != null && error.code.toUpperCase().includes("ECONNABORTED")) { // Error por TIMEOUT

                // Si es 1s, quiere decir que es un método que no necesita mantener la conexión o esperar una respuesta,
                //   entonces se pone 1s para que al segundo después de hecha la conexión al API se cancele y no cuente como error
                if (timeOut != 1000) {

                    console.log(error);
                    window.location = "#/lostConnection";

                }

            } else {

                console.log(error);

            }

            return error;
        });


        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.data != null) {

            returnResult = result.data;

        } else {

            returnResult = {
                ok: false
            };

        }

        return returnResult;

    },


    GenerarParametrosConObjeto(parametros) {

        try {

            var str = [];

            for (var p in parametros) {

                var valor = "";

                if (Object.prototype.hasOwnProperty.call(parametros, p)) {

                    valor = "";

                    valor = parametros[p];

                    if (valor instanceof Date) {

                        valor = valor.toISOString();

                    }

                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(valor));


                }

                //if (parametros.hasOwnProperty(p)) {
                //    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(parametros[p]));
                //}

            }

            return str.join("&");

        } catch (error) {

            return null;

        }

    }

}
