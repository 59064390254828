
import connectorr from '../../../common/connector.js';
let connector = new connectorr();

var apiControllerConnector = "Configuracion";

export default {

    getOne(parametros) { // ciaId

        return connector.Get(apiControllerConnector, "getOne", parametros);

    },

    GetOneConfiguracionMantenimiento(parametros) { // ciaId

        return connector.Get(apiControllerConnector, "GetOneConfiguracionMantenimiento", parametros);

    },

    postDefault(parametros) {

        return connector.Get(apiControllerConnector, "postDefault", parametros);

    },

    postAdd(data) { // Creo que ya no se usa, ahora es el de mantenimiento

        return connector.Post(apiControllerConnector, "postConfiguracion", data);

    },

    UpdateConfiguracionMantenimiento(data) {

        return connector.Post(apiControllerConnector, "UpdateConfiguracionMantenimiento", data);

    },

    GetInputFocusNuevoMovimiento(parametros) { // ciaId

        return connector.Get(apiControllerConnector, "GetInputFocusNuevoMovimiento", parametros);

    },

    GetServidorCorreoActivo(parametros) { // ciaId

        return connector.Get(apiControllerConnector, "GetServidorCorreoActivo", parametros);

    },

    GetIntegrations(parametros) { // ciaId

        return connector.Get(apiControllerConnector, "GetIntegrations", parametros);

    },

}