import axios from 'axios';
import config from '@/config.js';

export default class Connector {
    constructor(urlP, apiP) {

        this.url = ""; //  config.api_url;
        //this.pathAPI = "api/";
        this.pathAPI = ""; //  "/";
        this.returnResult = null;
        this.url2 = null;
        this.parametrosUrl = null;
        this.token = null;
        this.withCredentials = true;
        this.customErrorCode = "*ERRORAPI*";

        this.apiClient = null;

        if (urlP == null) {

            this.url = config.api_url;
            this.pathAPI = "/";

        } else {

            this.url = urlP;
            this.pathAPI = apiP;

        }

        this.apiClient = axios.create({
            baseURL: this.url
        });

    }

    async Get(Controller, Metodo, Parametros, timeOut) {



        //// Agregar un interceptor de respuesta
        //this.apiClient.interceptors.response.use(
        //    function (response) {

        //        //console.log("entra interceptor");
        //        alert("entra interceptor");

        //        // Si la respuesta es exitosa, devolverla directamente
        //        return response;
        //    },
        //    async function (error) {

        //        let resulttt = { ...error };
        //        resulttt.stack = null;
        //        //console.log("entra interceptor ERROR", resulttt);



        //        // Si hay un error en la respuesta, almacenar el cuerpo de la respuesta
        //        if (error.response && error.response.data) {
        //            error.responseData = await error.response.data.text();
        //        }
        //        return Promise.reject(error);
        //    }
        //);





        this.token = localStorage.getItem("tech_token");

        this.url2 = this.pathAPI + Controller + "/" + Metodo;


        if (timeOut == null)
            timeOut = config.httpTimeOut;

        Parametros = { ...Parametros };


        // Verifica si viene un objeto de parámetros para hacer el agregado a la URL
        this.parametrosUrl = null;
        if (Parametros != null) {

            this.parametrosUrl = this.GenerarParametrosConObjeto(Parametros);

            if (this.parametrosUrl != null) {

                this.url2 += "?" + this.parametrosUrl;

            } else {

                return { ok: false, msg: "No se pudo generar la consulta correctamente" }

            }

        }


        // Realiza la conexión al API
        var result = await this.apiClient.get(this.url2, {
            timeout: timeOut,
            withCredentials: this.withCredentials,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + this.token
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            if (error.code != null && error.code.toUpperCase().includes("ECONNABORTED")) { // Error por TIMEOUT

                // Si es 1s, quiere decir que es un método que no necesita mantener la conexión o esperar una respuesta,
                //   entonces se pone 1s para que al segundo después de hecha la conexión al API se cancele y no cuente como error
                if (timeOut != 1000) {

                    console.log(error);
                    window.location = "#/lostConnection";

                }

            } else {

                console.log(error);

            }

            return error;
        });


        //let resulttt = { ...result };
        //resulttt.stack = null;
        //console.log("resulttt", resulttt);
        //console.log(JSON.stringify(resulttt, null, 2));
        //console.log(result.response);


        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.data != null) {

            this.returnResult = result.data;

        } else {

            this.returnResult = {
                ok: false,
                msg: this.ValidateContextResultCustomErrorMessage(result)
            };

        }

        return this.returnResult;

    }
    async GetArray(Controller, Metodo, Parametros, timeOut) {

        this.token = localStorage.getItem("tech_token");

        this.url2 = this.pathAPI + Controller + "/" + Metodo;


        if (timeOut == null)
            timeOut = config.httpTimeOut;

        Parametros = { ...Parametros };


        // Verifica si viene un objeto de parámetros para hacer el agregado a la URL
        this.parametrosUrl = null;
        if (Parametros != null) {

            this.parametrosUrl = this.GenerarParametrosConObjeto(Parametros);

            if (this.parametrosUrl != null) {

                this.url2 += "?" + this.parametrosUrl;

            } else {

                return { ok: false, msg: "No se pudo generar la consulta correctamente" }

            }

        }


        // Realiza la conexión al API
        var result = await this.apiClient.get(this.url2, {
            timeout: timeOut,
            withCredentials: this.withCredentials,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + this.token
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            if (error.code != null && error.code.toUpperCase().includes("ECONNABORTED")) { // Error por TIMEOUT

                // Si es 1s, quiere decir que es un método que no necesita mantener la conexión o esperar una respuesta,
                //   entonces se pone 1s para que al segundo después de hecha la conexión al API se cancele y no cuente como error
                if (timeOut != 1000) {

                    console.log(error);
                    window.location = "#/lostConnection";

                }

            } else {

                console.log(error);

            }

            return error;
        });

        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.status == 200) { // Si el status, es porque no se hizo, automáticamente pasa abajo

            this.returnResult = {
                ok: true,
                obj: result.data
            };

        } else {

            if (result.data != null) {

                this.returnResult = result.data;

            } else {

                this.returnResult = {
                    ok: false,
                    msg: this.ValidateContextResultCustomErrorMessageArrayBuffer(result)
                };

            }
        }

        return this.returnResult;

    }
    async Post(Controller, Metodo, Data) {

        this.token = localStorage.getItem("tech_token");

        this.url2 = this.pathAPI + Controller + "/" + Metodo;


        Data = { ...Data };
        await this.ObjectConvertDatesToISOString(Data);

        // Realiza la conexión al API
        var result = await this.apiClient.post(this.url2, Data, {
            timeout: config.httpTimeOut,
            withCredentials: this.withCredentials,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + this.token
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            if (error.code != null && error.code.toUpperCase().includes("ECONNABORTED")) { // Error por TIMEOUT

                // Si es 1s, quiere decir que es un método que no necesita mantener la conexión o esperar una respuesta,
                //   entonces se pone 1s para que al segundo después de hecha la conexión al API se cancele y no cuente como error
                //if (timeOut != 1000) { // Por el momento comentado, ya que no se hace recibimiento de ese parámetro todavía

                console.log(error);
                window.location = "#/lostConnection";

                //}

            } else {

                console.log(error);

            }

            return error;

        });




        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.data != null) {

            this.returnResult = result.data;

        } else {

            this.returnResult = {
                ok: false,
                msg: this.ValidateContextResultCustomErrorMessage(result)
            };

        }

        return this.returnResult;

    }
    async PostArray(Controller, Metodo, Data, timeOut) {

        this.token = localStorage.getItem("tech_token");

        this.url2 = this.pathAPI + Controller + "/" + Metodo;


        if (timeOut == null)
            timeOut = config.httpTimeOut;

        Data = { ...Data };
        await this.ObjectConvertDatesToISOString(Data);

        // Realiza la conexión al API
        var result =
            await
                this.apiClient.post(this.url2, Data, {
                    timeout: timeOut,
                    withCredentials: this.withCredentials,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': 'Bearer ' + this.token
                    },

                }).then(function (value) {
                    return value;
                }).catch(error => {

                    if (error.code != null && error.code.toUpperCase().includes("ECONNABORTED")) { // Error por TIMEOUT

                        // Si es 1s, quiere decir que es un método que no necesita mantener la conexión o esperar una respuesta,
                        //   entonces se pone 1s para que al segundo después de hecha la conexión al API se cancele y no cuente como error
                        if (timeOut != 1000) {

                            console.log(error);
                            window.location = "#/lostConnection";

                        }

                    } else {

                        console.log(error);

                    }

                    return error;
                });

        if (result.status == 200) { // Si el status, es porque no se hizo, automáticamente pasa abajo

            this.returnResult = {
                ok: true,
                obj: result.data
            };

        } else {

            // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
            this.returnResult = {
                ok: false,
                msg: this.ValidateContextResultCustomErrorMessageArrayBuffer(result)
            };

        }

        return this.returnResult;

    }
    async PostForm(Controller, Metodo, Data) { // Form porque se puede enviar solo información pero también archivos FILE FILES ARCHIVO ARCHIVOS

        this.token = localStorage.getItem("tech_token");

        this.url2 = this.pathAPI + Controller + "/" + Metodo;


        Data = { ...Data };
        await this.ObjectConvertDatesToISOString(Data);


        // Realiza la conexión al API
        var result = await this.apiClient.post(this.url2, Data, {
            timeout: config.httpTimeOut,
            withCredentials: this.withCredentials,
            headers: {
                //'Content-Type': 'application/json; charset=UTF-8',
                'Content-Type': 'multipart/form-data; charset=UTF-8',
                'Authorization': 'Bearer ' + this.token
            }
        }).then(function (value) {
            return value;
        }).catch(error => {

            if (error.code != null && error.code.toUpperCase().includes("ECONNABORTED")) { // Error por TIMEOUT

                // Si es 1s, quiere decir que es un método que no necesita mantener la conexión o esperar una respuesta,
                //   entonces se pone 1s para que al segundo después de hecha la conexión al API se cancele y no cuente como error
                //if (timeOut != 1000) { // Por el momento comentado, ya que no se hace recibimiento de ese parámetro todavía

                console.log(error);
                window.location = "#/lostConnection";

                //}

            } else {

                console.log(error);

            }

            return error;

        });




        // Para evitar la validación en Controller de si es != null el objeto, ya inmediatamente se devuelve con ok en false
        if (result.data != null) {

            this.returnResult = result.data;

        } else {

            this.returnResult = {
                ok: false,
                msg: this.ValidateContextResultCustomErrorMessage(result)
            };

        }

        return this.returnResult;

    }
    GenerarParametrosConObjeto(parametros) {

        try {

            var str = [];

            for (var p in parametros) {

                var valor = "";

                if (Object.prototype.hasOwnProperty.call(parametros, p)) {

                    valor = "";

                    valor = parametros[p];

                    if (valor instanceof Date) {

                        //valor = valor.toISOString();

                        let year = valor.getFullYear();
                        let month = (valor.getMonth() + 1).toString().padStart(2, '0');
                        let day = valor.getDate().toString().padStart(2, '0');
                        let hours = valor.getHours().toString().padStart(2, '0');
                        let minutes = valor.getMinutes().toString().padStart(2, '0');
                        let seconds = valor.getSeconds().toString().padStart(2, '0');

                        valor = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

                    }

                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(valor));


                }

                //if (parametros.hasOwnProperty(p)) {
                //    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(parametros[p]));
                //}

            }

            return str.join("&");

        } catch (error) {

            return null;

        }

    }

    async ObjectConvertDatesToISOString(objeto) {

        var valor = "";

        for (var p in objeto) {

            if (Object.prototype.hasOwnProperty.call(objeto, p)) {

                valor = "";

                if (typeof objeto[p] === 'object') {

                    valor = objeto[p];

                    if (valor instanceof Date) {

                        let year = valor.getFullYear();
                        let month = (valor.getMonth() + 1).toString().padStart(2, '0');
                        let day = valor.getDate().toString().padStart(2, '0');
                        let hours = valor.getHours().toString().padStart(2, '0');
                        let minutes = valor.getMinutes().toString().padStart(2, '0');
                        let seconds = valor.getSeconds().toString().padStart(2, '0');

                        objeto[p] = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


                        //console.log(objeto[p]);

                        //valor = valor.toISOString();
                        //objeto[p] = valor;


                        //console.log((encodeURIComponent(p) + "=" + encodeURIComponent(valor)));

                    } else {

                        await this.ObjectConvertDatesToISOString(objeto[p]);

                    }



                } else {

                    valor = objeto[p];

                    if (valor instanceof Date) {

                        let year = valor.getFullYear();
                        let month = (valor.getMonth() + 1).toString().padStart(2, '0');
                        let day = valor.getDate().toString().padStart(2, '0');
                        let hours = valor.getHours().toString().padStart(2, '0');
                        let minutes = valor.getMinutes().toString().padStart(2, '0');
                        let seconds = valor.getSeconds().toString().padStart(2, '0');

                        objeto[p] = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


                        //valor = valor.toISOString();
                        //objeto[p] = valor;

                        //console.log(objeto[p]);

                    }

                    //console.log((encodeURIComponent(p) + "=" + encodeURIComponent(valor)));

                }

            }

        }

    }

    //async ObjectConvertDatesToISOString(objeto) {

    //    var valor = "";

    //    for (var p in objeto) {

    //        if (Object.prototype.hasOwnProperty.call(objeto, p)) {

    //            valor = "";

    //            //console.log("objeto[p]", objeto[p]);

    //            if (typeof objeto[p] === 'object') {

    //                valor = objeto[p];

    //                if (valor instanceof Date) {

    //                    let year = valor.getFullYear();
    //                    let month = (valor.getMonth() + 1).toString().padStart(2, '0');
    //                    let day = valor.getDate().toString().padStart(2, '0');
    //                    let hours = valor.getHours().toString().padStart(2, '0');
    //                    let minutes = valor.getMinutes().toString().padStart(2, '0');
    //                    let seconds = valor.getSeconds().toString().padStart(2, '0');

    //                    objeto[p] = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


    //                    //console.log(objeto[p]);

    //                    //valor = valor.toISOString();
    //                    //objeto[p] = valor;


    //                    //console.log((encodeURIComponent(p) + "=" + encodeURIComponent(valor)));

    //                } else {

    //                    await this.ObjectConvertDatesToISOString(objeto[p]);

    //                }



    //            } else {

    //                valor = objeto[p];

    //                if (valor instanceof Date) {

    //                    //console.log("¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡FECHITA BABY!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");

    //                    let year = 1999; // valor.getFullYear();
    //                    let month = (valor.getMonth() + 1).toString().padStart(2, '0');
    //                    let day = valor.getDate().toString().padStart(2, '0');
    //                    let hours = valor.getHours().toString().padStart(2, '0');
    //                    let minutes = valor.getMinutes().toString().padStart(2, '0');
    //                    let seconds = valor.getSeconds().toString().padStart(2, '0');

    //                    objeto[p] = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


    //                    //valor = valor.toISOString();
    //                    //objeto[p] = valor;

    //                    //console.log(objeto[p]);

    //                } else if (typeof objeto[p] === 'string') {

    //                    try {

    //                        const parsedObject = JSON.parse(objeto[p]);
    //                        if (typeof parsedObject === 'object') {

    //                            //console.log("------ENTRA OBJETO parsedObject");

    //                            await this.ObjectConvertDatesToISOString(parsedObject);
    //                            objeto[p] = JSON.stringify(parsedObject);

    //                            //console.log("------SALE OBJETO parsedObject");

    //                        }

    //                    } catch (error) {
    //                        // Si hay un error al analizar el JSON, no hacemos nada
    //                    }

    //                }

    //                //console.log((encodeURIComponent(p) + "=" + encodeURIComponent(valor)));

    //            }

    //        }




    //    }




    //    // Código anterior, supongo jeje

    //    //for (var clave in objeto) {

    //    //    //console.log("");

    //    //    //console.log(objeto[clave]);

    //    //    if (typeof objeto[clave] === 'object') {

    //    //        //console.log("objeto[clave]: == object");

    //    //        this.ObjectConvertDatesToISOString(objeto[clave]); // Llamada recursiva para verificar en objetos internos

    //    //    } else if (objeto[clave] instanceof Date) { // typeof objeto[clave] === 'object' &&

    //    //        //console.log("objeto[clave]: == Date");


    //    //        objeto[clave] = objeto[clave].toISOString();
    //    //        //console.log(objeto[clave]);


    //    //    }
    //    //}





    //}

    ValidateContextResultCustomErrorMessage(result) {

        

        //console.log("result", result);

        if (result?.response?.status == config.statusCodeExpiredToken) {

            this.CerrarSesion();

        }

        if (typeof result.response?.data === 'string' && result.response?.data?.includes(this.customErrorCode))
            return result.response.data.replace(this.customErrorCode, "");
        else
            return null;

    }
    ValidateContextResultCustomErrorMessageArrayBuffer(result) {

        //console.log("result", result);

        if (result?.response?.status == config.statusCodeExpiredToken) {

            this.CerrarSesion();

        }

        let resultMsg = null;
        if (result.response != null) {
            if (result.response.data != null) {
                // Actualmente los únicos que se pueden devolver personalizados
                // Solo acá se validan dado que no sé qué tanto puede devolver un "arrayBuffer" cuando es error, entonces para no hacer esto en todas las posibilidades, solo en las que puedan pasar
                if (result.response.status == 303 || result.response.status == 406 || result.response.status == 403) { //  || result.response.status == 401 // Por el momento 401 será solo por TOKEN vencido
                    let mensaje = new TextDecoder().decode(result.response.data);
                    if (mensaje != null && typeof mensaje === "string" && mensaje != "" && mensaje.includes(this.customErrorCode)) {
                        resultMsg = mensaje.replace(this.customErrorCode, "");
                    }
                }
            }
        }

        return resultMsg;

    }

    // También está este método en TobBar.vue
    CerrarSesion() {

        localStorage.removeItem("tech_token");
        localStorage.removeItem("tech_refreshtoken");
        localStorage.removeItem("tech_tokenexpires");
        localStorage.removeItem("tech_user");
        localStorage.removeItem("tech_userName");
        localStorage.removeItem("tech_uTipo");
        localStorage.removeItem("tech_cia");
        localStorage.removeItem("tech_ciaName");
        localStorage.removeItem("tech_userId");
        localStorage.removeItem("tech_usercIden");
        localStorage.removeItem("tech_usercta_id");
        localStorage.removeItem("tech_planId");
        localStorage.removeItem("tech_planNombre");
        localStorage.removeItem("tech_planVencimiento");
        localStorage.removeItem("tech_diasVigente");
        localStorage.removeItem("tech_ciaMoneda");
        localStorage.removeItem("tech_ciaPais");
        localStorage.removeItem("tech_ciaActualizacionRapida");
        localStorage.removeItem("tech_ciaMonedaConfiguracion");


        window.location.href = config.login_url;

    }



}
