
import connectorr from '../../../common/connector.js';
let connector = new connectorr();

var apiControllerConnector = "Generales";

export default {


    getCompannia(parametros) {  //ciaId

        return connector.Get(apiControllerConnector, "getCompannia", parametros);

    },

    GetListMonedas() {

        return connector.Get(apiControllerConnector, "GetListMonedas", null);

    },

    GetMonedaById(parametros) { // monedaId

        return connector.Get(apiControllerConnector, "GetMonedaById", parametros);

    },

    getListPaises() {

        return connector.Get(apiControllerConnector, "getPaises", null);

    },

    getListProvincias(parametros) { // pai_pk

        return connector.Get(apiControllerConnector, "getProvincias", parametros);

    },

    getListCantones(parametros) { // pai_pk, provincia_id

        return connector.Get(apiControllerConnector, "getCantones", parametros);

    },

    getListDistritos(parametros) { // pai_pk, provincia_id, canton_id

        return connector.Get(apiControllerConnector, "getDistritos", parametros);

    },

    getListBarrios(parametros) { // pai_pk, provincia_id, canton_id, distrito_id

        return connector.Get(apiControllerConnector, "getBarrios", parametros);

    },

    postCompanniEdit(data) {

        return connector.Post(apiControllerConnector, "postCompanniEdit", data);

    },

    postCompanniAdd(data) {

        return connector.Post(apiControllerConnector, "postCompanniAdd", data);

    },

    getTiposPersona(parametros) { // paisId

        return connector.Get(apiControllerConnector, "getTiposPersona", parametros);

    },

    getUsuarios(parametros) { // token

        return connector.Get(apiControllerConnector, "getUsuarios", parametros);

    },

    getUsuariosCia(parametros) { // cia_id, token

        return connector.Get(apiControllerConnector, "getUsuariosCia", parametros);

    },


    postInviteUser(parametros) {  //Datos, token

        return connector.PostForm(apiControllerConnector, "postInviteUser", parametros);

    },

    postEditUser(parametros) {  //Datos, token

        return connector.PostForm(apiControllerConnector, "postEditUser", parametros);

    },

    getDeleteUsuario(parametros) {  //cia_id, usuario_id, token

        return connector.Get(apiControllerConnector, "getDeleteUsuario", parametros);

    },

    getAssignUsuario(parametros) {  //cia_id, usuario_id, token

        return connector.Get(apiControllerConnector, "getAssignUsuario", parametros);

    },

    getUnassignUsuario(parametros) {  //cia_id, usuario_id, token

        return connector.Get(apiControllerConnector, "getUnassignUsuario", parametros);

    },

    postSaveProfile(data) {  //Datos, token

        return connector.PostForm(apiControllerConnector, "postSaveProfile", data);

    },

    GetReportePagoBancos(parametros) {  // token

        return connector.Get(apiControllerConnector, "GetReportePagoBancos", parametros);

    },

    GetReportesPagoPaginated(parametros) {  // cia_id, itemsPerPage, pagenumber, fechaInicio, fechaFinal, token

        return connector.Get(apiControllerConnector, "GetReportesPago", parametros);

    },

    PostReportePago(data) {

        return connector.Post(apiControllerConnector, "PostReportePago", data);

    },

    GetReportePago(parametros) {  // reportePago_id, token

        return connector.Get(apiControllerConnector, "GetReportePago", parametros);

    },

    DeleteReportePago(parametros) {  // reportePago_id, token

        return connector.Get(apiControllerConnector, "GetReportePagoDelete", parametros);

    },

    GetTiquetes(parametros) {  // fechaInicio, fechaFinal

        return connector.Get(apiControllerConnector, "GetTiquetes", parametros);

    },

    PostTiquete(data) {

        return connector.Post(apiControllerConnector, "PostTiquete", data);

    },

    GetPlanIdFromToken() {

        return connector.Get(apiControllerConnector, "GetPlanIdFromToken");

    }


}
