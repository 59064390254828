
import { notify } from '@kyvg/vue3-notification';

const NotificacionesCustom = (app) => {

    function UseNotifyCustom() {

        return {

            // tipo:  1: success  2: error   3: warn
            // msg: Es el mensaje principal, pero puede venir null de un callback.msg, entonces se obtendría el msg2 en dicho caso
            // sec: En caso de que sea una notificación con tiempo establecido, vendrá en segundos, no en miles
            Notificacion(tipoId, msg, msg2, titulo, secP) {

                var tipo = "warn";
                var sec = 3000;


                if (secP != null)
                    sec = secP * 1000;

                if (titulo == null)
                    titulo = "";

                switch (tipoId) {
                    case 1:

                        tipo = "success";

                        break;
                    case 2:

                        tipo = "error";

                        break;
                    case 3:

                        tipo = "warn";

                        break;
                }

                notify({
                    title: titulo,
                    text: (msg != null && msg != "" ? msg : msg2),
                    duration: sec,
                    type: tipo,
                    group: "app"
                });


            },

            NotificacionRedirect(tipoId, msg, msg2, titulo, secP, redirect) {

                if (secP == null)
                    secP = 4;

                this.Notificacion(tipoId, msg, msg2, titulo, secP);

                window.location.href = "#" + redirect;

            }

        }
    }

    const instance = UseNotifyCustom();

    app.config.globalProperties.$notify = instance;
    app.provide('$notify', instance)



};

export { NotificacionesCustom };
