import { useLoading } from 'vue-loading-overlay';

const LoadingPluginCustom = (app) => {

    function UseLoadingCustom() {

        var loadingBaborsh = null;
        var loading = useLoading();

        return {

            show() {

                if (loadingBaborsh == null)
                    loadingBaborsh = loading.show();

            },

            hide() {

                if (loadingBaborsh != null) {

                    loadingBaborsh.hide();
                    loadingBaborsh = null;

                }



            }

        }
    }


    const instance = UseLoadingCustom();

    app.config.globalProperties.$loading = instance;
    app.provide('$loading', instance)



};

export { LoadingPluginCustom };
